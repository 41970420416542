/* $primary: #2196f3; */
/* index.css or App.css */
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

.App {
  /* text-align: center; */
}

body {
  font-family: "Open Sans", sans-serif;
  height: 100vh;
}

input {
  margin: 10px;
}

@keyframes spinner {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(359deg);
  }
}

* {
  box-sizing: border-box;
}



.wrapper {
  display: flex;
  /* position: fixed; */
  align-items: center;
  /* flex-direction: column; */
  justify-content: center;
  width: 100%;
  min-height: 100%;
  /* padding: 20px; */
  background: rgba(darken(#2196f3, 90%), 22.85);
}
@media(max-width:768px) {
  .wrapper{
    padding: 10px;
  }
}

.body-str {
  border-radius: 2px 2px 5px 5px;
  width: 100%;
  /* background: #ffffff; */
  padding: 10px 40px 20px 40px;

}

.login {
  border-radius: 10px;
  width: 35%;
  /* max-width: 420px; */
  background: #ffffff;
  /* position: relative; */
  /* padding-bottom: 15px; */
  opacity: 0.9;
}
@media(max-width: 768px) {
  .login{
    width: 80%;
  }
}

h2 {
  background: linear-gradient(90deg, rgba(255,0,112,1) 0%, rgba(0,0,0,1) 100%);;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


div a {
  text-decoration: none;
  color:rgb(41, 41, 41) !important;
  cursor: pointer;
}

div a:hover{
  color:rgb(16, 29, 212) !important
}

div a:active{
  color:rgb(10, 251, 10) !important
}