.add-player{
    margin: 10% 30%;
    
}
.add-player h1{
    color:rgb(123, 119, 119);
    text-align: center;
}

.add-player-wrapper{
    width:50%;
    margin:0 auto;
}

.player-list{
    margin:5% auto;
}

.player-list-wrapper{
    width:60%;
    margin:0 auto;
}

.name-col{
    display: flex;
}

.name-col button{
    width: 80px;
}