.add-match-body {
    background: linear-gradient(135deg, #141E30, #243B55);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: 'Arial', sans-serif;
  }
  
  .glassmorphic-container {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 15px;
    padding: 20px;
    max-width: 600px;
  }
  
  .add-match-label {
    color: #00ffcc;
  }
  
  .add-match-input {
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.4);
    color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .add-match-input:focus {
    outline: none;
    border-color: #00ffcc;
    box-shadow: 0 0 10px #00ffcc;
  }
  
  match-button {
    background: linear-gradient(90deg, #00ffcc, #3366ff);
    border: none;
    padding: 10px;
    font-size: 16px;
    transition: all 0.3s;
  }
  
  match-button:hover {
    background: linear-gradient(90deg, #3366ff, #00ffcc);
  }
  