.list-items:hover{
    background-color: rgb(225, 225, 225);
}

.card-group{
    /* height: 300px; */
    display: flex;
    flex-direction: column;
}

.betting-guide{
    text-align: center;
    margin: 0 100px;
    cursor: pointer;
}

.betting-guide .betting-heading{
    color:red !important;
}
.sale{
    margin: 0;
}

.betting-guide-heading{
    margin-top: 270px;
    /* border: 1px solid red; */
}
.guides-row{
    margin:0;
}
.card-group .card-element{
    display: flex;
    padding: 1em;
    margin: 1em;

    /* border: 1px solid black;
    borf */
}
.card-group .card-element .card-media{
    width: 200px;
    background-position:center;
}
.card-group .card-element .text-content{
    font-size: 20px;
    /* color: red; */
}

.flag-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh;
    width: 100%;
    cursor: pointer;
    overflow: hidden;
}

.flag-image {
    width: 50%;
    height: auto;
}

.vs-text {
    position: absolute;
    font-size: 2rem;
    font-weight: bold;
    color: transparent;
    transition: color 0.3s ease;
}

.flag-container .vs-text {
    color: red;
    background: #f0f8ff75;
    border: 2px solid;
    border-radius: 50%;
}

.title-container {
    height: 3em;
    overflow-y: auto;
    word-wrap: break-word;
    display: flex;
    align-items: center;
    text-align: center;

}

.card-cnt{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .btn-cnt{
        margin-top: auto;
        button{
            padding: 0;
            border: 0;
            margin: 0.5em;
        }
    }
}

.live-banner {
    position: absolute;
    top: 10px;
    right: 10px;
    background: red;
    color: white;
    padding: 5px 12px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(255, 0, 0, 0.8);
    animation: liveBlink 1s infinite alternate;
}

@keyframes liveBlink {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0.5;
        transform: scale(1.1);
    }
}


.upcoming-banner {
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 0.5;
    background: rgb(216, 216, 106);
    color: rgb(55, 51, 51);
    padding: 5px 12px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(141, 141, 67, 0.8);
}