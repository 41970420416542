html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
}

.header {
  background-color: #1a73e8;
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header nav a {
  color: white;
  margin: 0 15px;
  text-decoration: none;
}

.auth-links a {
  color: white;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.hero {
  background-color: rgba(185, 222, 248, 0.2);
  padding: 50px 20px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
  width: 80%;
}

.hero h1 {
  margin-bottom: 20px;
}

.hero button {
  padding: 10px 20px;
  background-color: #1a73e8;
  color: white;
  border: none;
  cursor: pointer;
}

.predictions {
  display: flex;
  justify-content: space-around;
  margin: 20px;
}

.prediction-left,
.prediction-right {
  width: 30%;
}

.prediction-center {
  box-shadow: 2px 10px 15px rgba(0, 0, 0, 0.13);
  width: 40%;
}

.prediction-card {
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 30%;
}

.carousel-container {
  width: 90%;
  padding: 20px;
}

.crousal {
  position: relative;
}

.slider-image {
  width: 100%;
  height: 300px;
  /* Adjust height as needed */
  object-fit: cover;
}

.overlay-text {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
  font-size: 24px;
  font-weight: 600;
  z-index: 10;
  font-family: ui-sans-serif;
}

.slick-prev,
.slick-next {
  background: blue;
  /* Change to your desired color */
  border-radius: 50%;
  /* Makes the buttons round */
  color: #89c0f3;
  /* Color of the arrows */
  text-align: center;
  /* Center the arrows horizontally */
  z-index: 1;
  /* Ensure the arrows are on top of other elements */
}

.footer {
  background-color: #1a73e8;
  color: white;
  padding: 20px;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-links {
  display: flex;
  justify-content: center; /* Centers the items horizontally */
  margin-bottom: 10px;
}

.footer-link {
  color: white;
  text-decoration: none;
  cursor: pointer;
  margin: 0 15px; /* Space between links */
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .header nav a {
    margin: 5px 0;
  }

  .hero {
    padding: 30px 10px;
    flex-direction: column;
    text-align: center;
  }

  .hero h1 {
    font-size: 24px;
  }

  .hero button {
    padding: 8px 16px;
    font-size: 14px;
  }

  .predictions {
    flex-direction: column;
    align-items: center;
  }

  .prediction-left,
  .prediction-right {
    width: 100%;
    margin-bottom: 20px;
  }

  .prediction-center {
    width: 100%;
    margin-bottom: 20px;
  }

  .prediction-card {
    width: 100%;
    margin-bottom: 20px;
  }

  .carousel-container {
    width: 90%;
    padding: 10px;
  }

  .slider-image {
    height: 200px;
    /* Adjust height for mobile view */
  }

  .overlay-text {
    bottom: 10px;
    left: 10px;
    font-size: 18px;
  }

  .footer {
    padding: 15px;
  }

  .footer a {
    display: block;
    margin-bottom: 10px;
  }

  .slider-image {
    height: 200px;
    /* Adjust height for mobile view */
  }

  .overlay-text {
    bottom: 10px;
    left: 10px;
    font-size: 18px;
  }
}

/* Styles for Larger Screens */
@media (min-width: 769px) {

  /* Ensure styles are optimized for tablets and larger screens if needed */
  .hero {
    padding: 50px 20px;
  }

  .carousel-container {
    width: 90%;
    padding: 20px;
  }
}