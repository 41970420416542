.navbar-website{
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    margin-bottom: 15%;
}
.dropdown-toggle::after {
    display: none !important; 
  }
  .bar-background {
    color: -webkit-linear-gradient(0deg, rgba(179,29,181,1) 0%, rgba(50,175,150,1) 10%, rgba(60,60,167,1) 17%, rgba(42,188,218,1) 25%, rgba(39,255,0,1) 33%, rgba(205,0,255,0.7819502801120448) 41%, rgba(0,35,255,0.7819502801120448) 48%, rgba(9,255,0,0.7819502801120448) 55%, rgba(7, 97, 100, 0.782) 63%);
  }

  .cricket-prediction {
    text-align: center;
  }
  
  .cricket-player-icon {
    display: none;
    margin: 0 auto;
    background-image: url('http://cricketpredict.in/cricket-player.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 50px;
    height: 50px;
  }
  
  .cricket-prediction-text {
    display: inline;
  }
  
  @media (min-width: 768px) {
    .cricket-prediction-text {
      display: inline;
    }
  }

  @media (min-width: 768px) {
    .cricket-player-icon {
      display: inline;
    }
  }
  
  
  
  
  
  